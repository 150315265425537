import axios from "axios";


class HttpClient {
    constructor(url) {
        this.jwt = "6341e60a35a4a291e37eff95a1e311552a62672cad38d5e5af022ca07388f3014c742400d1fdb1a1a9fbe4425d870d02f6c437169fb3487856a6597d0e6a544aa0c46464672f39f6edc9959be2add2faad1c3280dd8e2a719b0b7ee241e7fa8beaa551eca256fe0518fc4fd16fe0e090e6ce48e44e9e1759b19407bf90cdd2ee"
        this.webApiUrl = "https://smartlabelindex.scanbuy.com/api"

        this.instance = axios.create({
            baseURL: this.webApiUrl,
            headers: {
                Authorization: "Bearer " + this.jwt
            }
        });
    }

    setJwt(jwt) {
        this.jwt = jwt
        return this
    }



    async get(url, queryParams = {}) {

        try {
            const response = await this.instance.get(url, {
                params: queryParams
            });

            if(response && response.status === 401){
                return null;
            }

            if(response && response.status !==401) {
                return response.data;
            }
            return null;
        } catch (e) {
            return null;
        }
    }
}

export default HttpClient;
