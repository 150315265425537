import HttpClient from "../services/HttpClient";

class Manager {

    constructor(httpClient = null) {
        this.httpClient = httpClient || new HttpClient()
    }

    async initData(params = {}) {
        const resFromFetchAllBrands = await this.fetchAllBrands()
        if (params.brandName) {
            const brandResponse = await this.fetchBrand(params.brandName.replace(/-/g, " "))
            const brand = brandResponse ? brandResponse?.data[0] : ""
            const brandFoundInList = resFromFetchAllBrands?.data.find((item) => {
                return item.attributes.name.toLowerCase().replace(/'/g, "").replace(" ", "-").includes(params.brandName.toLowerCase())
            })
            const queryParams = {brands: {name: {"$containsi": brand?.attributes?.name || brandFoundInList?.attributes?.name}}}
            const brandAlreadyExist = resFromFetchAllBrands?.data.find((item) => {
                return item.attributes.name === brand?.attributes?.name || item.attributes.name === brandFoundInList?.attributes?.name
            })
            const brandsList = (!brandFoundInList && !brandAlreadyExist && brand) ? [...resFromFetchAllBrands?.data, brand] : resFromFetchAllBrands?.data
            return {
                products: await this.fetchAllProducts(queryParams, 1, 10),
                brandsList: brandsList,
                initialBrand: brand || brandFoundInList
            }
        } else {
            return {
                products: await this.fetchAllProducts({}, 1, 10),
                brandsList: resFromFetchAllBrands?.data
            }
        }
    }

    async fetchAllProducts(queryParams, pageNumber = 1, pageSize) {
        queryParams = {
            populate: "brands",
            sort: "name",
            pagination: {
                page: pageNumber,
                pageSize: pageSize,
            },
            filters: {
                ...queryParams,
                locale: "en",
            }
        }
        const url = `/products`
        try {
            const res = await this.httpClient.get(url, queryParams);
            if (res) {
                return res
            } else {
                return null
            }
        } catch (e) {
            console.error("Failed to get product", e);
        }
        return null;
    }

    async fetchAllBrands() {
        const queryParams = {
            populate: "brands",
            pagination: {
                page: 1,
                pageSize: 200,
            },
            filters: {
                locale: "en",
            }
        }
        const url = `/brands`
        try {
            const res = await this.httpClient.get(url, queryParams);
            if (res) {
                return res
            } else {
                return null
            }
        } catch (e) {
            console.error("Failed to get brands", e);
        }
        return null;
    }

    async fetchBrand(brandName) {
        const queryParams = {
            populate: "brands",
            locale: "en",
            filters: {
                name: {"$containsi": brandName},
                locale: "en",
            }
        }
        const url = `/brands`
        try {
            const res = await this.httpClient.get(url, queryParams);
            if (res) {
                return res
            }
        } catch (e) {
            console.error("Failed to get brands", e);
        }
        return null;
    }

    async fetchBrandImage(brandName) {
        const queryParams = {
            populate: "headerLogo",
            locale: "en",
            filters: {
                name: brandName,
                locale: "en",
            }
        }
        const url = `/brands`
        try {
            const res = await this.httpClient.get(url, queryParams);
            if (res) {
                return res
            }
        } catch (e) {
            console.error("Failed to get brands", e);
        }
        return null;
    }
}


export default Manager;
